import {
  Alert,
  Box,
  Image, Text,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { FirstModalBody } from '../../agenda/_components/FirstModalBody.component';
import { StonlyMotivational } from '../../agenda/_components/StonlyMotivational.component';
import MyGoals from '../../profile/_components/MyGoals.component';
import TypicalWeek from '../../profile/_components/TypicalWeek.component';

type OnboardingDefaultType = {
  nextStep: () => void;
  previousStep: () => void;
  quitModal: () => void;
  welcomeStep: number;
  setWelcomeStep: React.Dispatch<React.SetStateAction<number>>;
}

export const OnboardingDefault: FC<OnboardingDefaultType> = ({
  nextStep,
  previousStep,
  quitModal,
  welcomeStep,
  setWelcomeStep,
}) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  const DEMO_STEP = 10;

  return (
    <>
      <FirstModalBody
        show={welcomeStep === 0}
        buttonsPosition="center"
        primaryButton={{ label: 'Começar', onClick: nextStep }}
      >
        <Image width="12rem" marginBottom="3rem" marginX="auto" src="/images/aristo-design/c1-1.png" />
        <Text fontSize="1.5rem" marginBottom={1} fontWeight="bold">
          Bem-vindo à
          <Box color={colors.secondary.goALittleDarker} display="inline" as="span">{' Aristo Class'}</Box>
          !
        </Text>
        <Alert
          variant="left-accent"
          bgColor={colors.lighter.goAlmostFullDarker}
          borderColor={colors.secondary.goALittleDarker}
          margin="1rem 0 1.5rem 0"
        >
          A melhor plataforma preparatória de Residência médica e revalidação do Brasil!
        </Alert>

        <Text marginBottom={2}>
          Aqui, na plataforma, deixaremos você preparado para escolher a Residência médica do seu sonho!
          Se você pretende revalidar o seu diploma médico, temos o ambiente ideal!
        </Text>
        <Text marginBottom={8}>
          Utilizaremos excelentes recursos, como videoaulas, flashcards, questões comentadas e mapas mentais.
          Vamos lá?
        </Text>
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 1}
        returnButton={{ label: 'Motivacional', onClick: previousStep }}
        primaryButton={{ label: 'Próximo', onClick: nextStep }}
      >
        <StonlyMotivational />
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 2}
        returnButton={{ label: 'Boas-vindas', onClick: previousStep }}
        primaryButton={{ label: 'Próximo', onClick: nextStep }}
      >
        <iframe
          width="100%"
          height={device === 'web' ? '405' : '180'}
          src="https://player.vimeo.com/video/1069657427?h=b1963e67e8&amp"
          title="Boas-vindas"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Alert
          variant="left-accent"
          bgColor={colors.lighter.goAlmostFullDarker}
          borderColor={colors.secondary.goALittleDarker}
          marginTop={4}
        >
          Nas próximas duas telas, solicitaremos informações essenciais para que
          sua experiência seja personalizada.
        </Alert>
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 3}
        returnButton={{ label: 'Meus objetivos', onClick: previousStep }}
      >
        <Text marginBottom={4} fontWeight="bold" fontSize="1.3rem">
          Marque abaixo quais provas de residência médica ou de revalidação você pretende prestar.
        </Text>
        <Text marginBottom={4}>
          Essas informações podem ser alteradas a qualquer momento, mas são importantes para a
          individualização do seu cronograma. Caso ainda não saiba, deixe em branco e
          edite essas informações na página de perfil assim que definir as provas.
        </Text>
        <Text marginBottom={4} color={colors.secondary.goALittleDarker}>
          Atenção: A ordem escolhida implicará no peso que daremos a cada instituição.
        </Text>
        <MyGoals
          primaryButtonText="Próximo"
          hideSuccessToast
          justifyButtons={device === 'mobile' ? 'center' : 'end'}
          primaryButtonStyle={{
            width: '90%',
            maxWidth: '15rem',
          }}
          neverDisableSave
          disableUndo
          onSave={nextStep}
        />
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 4}
        returnButton={{ label: 'Semana típica', onClick: previousStep }}
      >
        <Text marginBottom={2} fontWeight="bold" fontSize="1.2rem">
          Organize aqui a sua semana para cumprir as metas semanais do seu curso.
        </Text>
        <Text marginBottom={2}>
          A configuração deve ser feita individualmente
          para cada um dos cursos que possuir. Ela pode ser alterada a qualquer
          momento na página de perfil.
        </Text>
        <Text marginBottom={4}>
          As
          <Box color={colors.secondary.goALittleDarker} display="inline" as="span">{' metas mínimas '}</Box>
          indicam o número de atividades que você deve colocar para um estudo de alta produtividade.
          Atingindo as metas mínimas nos quatro tipos de atividades da semana, você recebe um troféu semanal. Escolha:
        </Text>
        <TypicalWeek
          isOnboarding
          hideSuccessToast
          primaryButtonText="Próximo"
          justifyButtons={device === 'mobile' ? 'center' : 'end'}
          primaryButtonStyle={{
            marginTop: '1rem',
            width: '90%',
            maxWidth: '15rem',
          }}
          disableUndo
          onSave={nextStep}
        />
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 5}
        returnButton={{ label: 'Tutorial', onClick: previousStep }}
        secondaryButton={{ label: 'Pular tutoriais', onClick: () => setWelcomeStep(DEMO_STEP) }}
        primaryButton={{ label: 'Ir para tutoriais', onClick: nextStep }}
      >
        <Text fontSize="1.3rem" marginBottom={2} fontWeight="bold">
          Parabéns! Agora sabemos mais sobre você!
        </Text>
        <Text marginBottom={2}>
          Após selecionar as provas da sua preferência e estruturar a sua semana de estudos,
          preparamos, com muito carinho, alguns tutoriais para você usar a plataforma Aristo da melhor
          maneira possível.
        </Text>
        <Text marginBottom={2}>
          Assim, você terá 100% do domínio dos nossos recursos e aproveitará todo o conteúdo disponível.
          Se preferir ver os tutoriais depois, eles estarão disponíveis no menu lateral.
        </Text>
        <Alert
          variant="left-accent"
          bgColor={colors.lighter.goAlmostFullDarker}
          borderColor={colors.secondary.goALittleDarker}
          marginBottom={12}
        >
          Nas próximas telas, mostraremos tutoriais importantes para o seu
          melhor entendimento das funcionalidades da AristoClass.
        </Alert>
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 6}
        returnButton={{ label: 'Tutorial - Agenda de estudos', onClick: previousStep }}
        secondaryButton={{ label: 'Pular tutoriais', onClick: () => setWelcomeStep(DEMO_STEP) }}
        primaryButton={{ label: 'Próximo', onClick: nextStep }}
      >
        <iframe
          title="Tutorial - Agenda de estudos"
          id="ston-42t4TJt0Kb"
          width="100%"
          height="100%"
          frameBorder="0"
          name="StonlyExplanation"
          allowFullScreen
          src="https://stonly.com/embed/42t4TJt0Kb/view/"
        />
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 7}
        returnButton={{ label: 'Tutorial - Desempenho', onClick: previousStep }}
        secondaryButton={{ label: 'Pular tutoriais', onClick: () => setWelcomeStep(DEMO_STEP) }}
        primaryButton={{ label: 'Próximo', onClick: nextStep }}
      >
        <iframe
          title="Tutorial - Desempenho"
          id="ston-nJxm6Mak0B"
          width="100%"
          height="815"
          frameBorder="0"
          name="StonlyExplanation"
          allowFullScreen
          src="https://stonly.com/embed/nJxm6Mak0B/view/"
        />
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 8}
        returnButton={{ label: 'Tutorial - Lista de temas', onClick: previousStep }}
        secondaryButton={{ label: 'Pular tutoriais', onClick: () => setWelcomeStep(DEMO_STEP) }}
        primaryButton={{ label: 'Próximo', onClick: nextStep }}
      >
        <iframe
          title="Tutorial - Lista de temas"
          id="ston-Uzr6DEKCUq"
          width="100%"
          height="815"
          frameBorder="0"
          name="StonlyExplanation"
          allowFullScreen
          src="https://stonly.com/embed/Uzr6DEKCUq/view/"
        />
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 9}
        returnButton={{ label: 'Tutorial - Histórico de Provas e Simulados', onClick: previousStep }}
        primaryButton={{ label: 'Próximo', onClick: () => setWelcomeStep(DEMO_STEP) }}
      >
        <iframe
          title="Tutorial - Histórico de Provas e Simulados"
          id="ston-fnP3QHhoPG"
          width="100%"
          height="815"
          frameBorder="0"
          name="StonlyExplanation"
          allowFullScreen
          src="https://stonly.com/embed/fnP3QHhoPG/view/"
        />
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 10}
        returnButton={{ label: 'Passos iniciais', onClick: previousStep }}
        primaryButton={{ label: 'Finalizar', onClick: quitModal }}
      >
        <Image marginX="auto" src="/images/onboarding/themeLists.png" />
        <Alert
          variant="left-accent"
          bgColor={colors.lighter.goAlmostFullDarker}
          borderColor={colors.secondary.goALittleDarker}
          marginTop={4}
        >
          Agora que você já aprendeu os primeiros passos, vá até a Lista de Temas e faça o download do Planner.
          Assim, você fica por dentro de tudo que está programado para sua semana!
        </Alert>
      </FirstModalBody>
    </>
  );
};
