import { FC } from 'react';
import { PrimaryButton } from '../../../../../lib/components/PrimaryButton';

interface HarrisonButtonProps {
  askHarrison: () => Promise<void>,
}

export const HarrisonButton: FC<HarrisonButtonProps> = ({
  askHarrison,
}) => {
  return (
    <PrimaryButton
      onClick={askHarrison}
      disabled={false}
      fontSize="14px"
      maxWidth="240px"
    >
      Obter resposta
    </PrimaryButton>
  );
};
