import {
  Divider, Flex, HStack, Image, Modal,
  ModalBody, ModalContent, ModalFooter, ModalHeader,
  ModalOverlay, Text, Textarea,
} from '@chakra-ui/react';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFlashcardsCRUD } from '../../../../api/decks';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { getParam } from '../../../../hooks/useSearchParam';
import Select from '../../../../lib/components/Select';
import { FlashcardButton } from '../../../_components/FlashcardButton.component';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';

type CreateFlashcardModalType = {
  isOpen: boolean,
  onClose: () => void,
}

export const CreateFlashcardModal: FC<CreateFlashcardModalType> = ({
  isOpen, onClose,
}: CreateFlashcardModalType) => {
  const currLessonId = getParam('lessonId');
  const {
    refetch,
    remainingLessons,
    listOfAllLessons,
    lessonName,
    setSkip,
    resetCurrContentSearchBar,
  } = useContext(FlashcardsCreationContext);
  const { colors, hueRotate } = useDefaultStyles();
  const { handleCreateFlashcard } = useFlashcardsCRUD();
  const [selectedLesson, setSelectedLesson] = useState('');
  const [frontValue, setFrontValue] = useState('');
  const [backValue, setBackValue] = useState('');
  const [createLoading, setCreateLoading] = useState(false);

  const onCreateFlashcardClick = useCallback(async () => {
    setCreateLoading(true);
    await handleCreateFlashcard({
      front: frontValue,
      back: backValue,
      lessonId: selectedLesson,
    });
    if (refetch) {
      resetCurrContentSearchBar();
      await refetch({ targets: 'all' });
    }
    setCreateLoading(false);
    setFrontValue('');
    setBackValue('');
    onClose();
    if (setSkip) {
      setSkip(0);
    }
  }, [
    backValue, frontValue, handleCreateFlashcard, onClose,
    refetch, resetCurrContentSearchBar, selectedLesson, setSkip]);

  const options = useMemo(() => {
    if (listOfAllLessons && remainingLessons) {
      return listOfAllLessons
        .sort((a, b) => (a.lessonTitle > b.lessonTitle ? 1 : -1))
        .map(x => ({ label: x.lessonTitle, value: x.lessonId }));
    } return undefined;
  }, [listOfAllLessons, remainingLessons]);

  useEffect(() => {
    if (options) {
      setSelectedLesson(options[0]?.value);
    }
    if (lessonName) {
      setSelectedLesson(currLessonId);
    }
  }, [currLessonId, options, lessonName]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bgColor={colors.primary.goALittleLighter}
        borderRadius={20}
        overflow="hidden"
        w={400}
        h={500}
        mx={2}
      >
        <ModalHeader
          bgColor={colors.primary.goALittleLighter}
          w="100%"
          h={100}
        >
          <Flex justify="space-between">
            <Text color={colors.lighter.keep}>
              Criação de flashcard
            </Text>
            <Image filter={hueRotate()} w="80px" src="/images/flash-card.svg" alt="flash" />
          </Flex>
        </ModalHeader>
        <ModalBody align="center">
          {
            lessonName !== 'Aula não encontrada' ? (
              <Text color={colors.lighter.keep}>
                {lessonName}
              </Text>
            ) : (
              <Select
                color={colors.lighter.keep}
                border="none"
                value={selectedLesson}
                onChange={e => setSelectedLesson(e.target.value)}
                required
                optionBgColor={colors.primary.goALittleLighter}
                _focus={{ border: 'none' }}
                width="100%"
                variant="outline"
                options={[{ label: 'Selecione um tema', value: '' }, ...(options || [])]}
              />
            )
          }
          <Divider mb={5} />
          <Flex flexFlow="column" mb={3}>
            <Text alignSelf="flex-start" color={colors.lighter.keep}>Frente</Text>
            <Textarea
              h={100}
              resize="none"
              color={colors.lighter.keep}
              value={frontValue}
              onChange={e => setFrontValue(e.target.value)}
              variant="unstyled"
              p={2}
              border="1px dashed"
              w="100%"
            />
          </Flex>
          <Flex flexFlow="column">
            <Text alignSelf="flex-start" color={colors.lighter.keep}>Verso</Text>
            <Textarea
              h={100}
              resize="none"
              color={colors.lighter.keep}
              value={backValue}
              onChange={e => setBackValue(e.target.value)}
              variant="unstyled"
              p={2}
              border="1px dashed"
              w="100%"
            />
          </Flex>
          <Text fontSize="xs" color={colors.red.keep} mt={3}>Atenção: este flashcard poderá ser visto por todos.</Text>
        </ModalBody>
        <ModalFooter
          w="100%"
          h={60}
        >
          <HStack justify="center" w="100%">
            <FlashcardButton
              bgColor={colors.red.goDarker}
              label="Cancelar"
              onClick={onClose}
              isLoading={createLoading}
              disabled={createLoading}
            />
            <FlashcardButton
              bgColor={colors.green.goDarker}
              label="Criar"
              onClick={onCreateFlashcardClick}
              isLoading={createLoading}
              disabled={createLoading || !frontValue || !backValue || selectedLesson === ''}
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
};
