import { fetchAndActivate, getString } from 'firebase/remote-config';
import {
  FC,
  HtmlHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Modal, ModalBody, ModalContent,
  ModalOverlay, Flex, Text,
  useDisclosure, ModalCloseButton, IconButton,
} from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { remoteConfig } from '../../client/firebase';
import { PrimaryButton } from './PrimaryButton';
import { useDevice } from '../../hooks/useDevice';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { Loading } from './Loading';

interface TutorialModalComponentProps extends HtmlHTMLAttributes<HTMLDivElement> {
  firebaseTutorialName: string;
  title: string;
  description: string;
  buttonText?: string;
  faIcon?: IconDefinition;
  onClose?: () => void;
}

const TutorialModal: FC<TutorialModalComponentProps> = ({
  firebaseTutorialName,
  title,
  description,
  buttonText = 'Entendi!',
  faIcon = faQuestion,
  onClose,
}) => {
  const [videoLink, setVideoLink] = useState<string | undefined>();

  const { isOpen, onOpen, onClose: closeModal } = useDisclosure();

  const device = useDevice();
  const { colors } = useDefaultStyles();

  const handleFirebaseLink = useCallback(async () => {
    await fetchAndActivate(remoteConfig);
    const result = getString(remoteConfig, firebaseTutorialName);

    setVideoLink(result);
  }, [firebaseTutorialName]);

  useEffect(() => {
    handleFirebaseLink();
  }, [handleFirebaseLink]);

  const isLoading = useMemo(() => {
    return videoLink === undefined;
  }, [videoLink]);

  const height = useMemo(() => {
    return device === 'web' ? '405' : '180';
  }, [device]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    closeModal();
  }, [onClose, closeModal]);

  const missingVideoLink = useMemo(() => videoLink === '', [videoLink]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="580px" borderRadius="16px">
          <ModalCloseButton />
          <ModalBody px="24px" py="16px">
            <Flex flexDir="column" alignItems="center">
              <Text
                fontWeight="bold"
                textAlign="center"
                mb="4px"
                fontSize="16px"
              >
                {title}
              </Text>
              <Text
                textAlign="center"
                color={colors.lightGray}
                fontSize="14px"
                mb="24px"
              >
                {description}
              </Text>
            </Flex>

            {isLoading && (
              <Flex sx={{ height }}>
                <Loading />
              </Flex>
            )}

            {!isLoading && missingVideoLink ? (
              <Text
                fontWeight="bold"
                textAlign="center"
                fontSize="16px"
              >
                Erro ao carregar o vídeo tutorial!
              </Text>
            ) : (
              <iframe
                width="100%"
                height={height}
                src={videoLink}
                title="Boas-vindas"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}

            <PrimaryButton
              isFullWidth
              mt="16px"
              onClick={handleClose}
            >
              {buttonText}
            </PrimaryButton>
          </ModalBody>
        </ModalContent>
      </Modal>
      <IconButton
        aria-label="tutorial"
        isRound
        size="xs"
        fontSize="12px"
        backgroundColor={colors.secondary.keep}
        color={colors.background}
        icon={<FontAwesomeIcon icon={faIcon} />}
        onClick={onOpen}
      />
    </>
  );
};

export default TutorialModal;
